<template>
  <div>
    <div class="header">
      <el-row :gutter="30" justify="space-between">
        <el-col :offset="1" :xs="5" :sm="5" :md="5" :lg="5">
          <div class="logo">
            <router-link to="/"
              ><img src="@/assets/logo/billowlink.png" alt=""
            /></router-link>
          </div>
        </el-col>
        <el-col :offset="3" :xs="15" :sm="15" :md="15" :lg="15">
          <div class="navigation hidden-md-and-down">
            <ul>
              <router-link to="/"
                ><li>
                  <div
                    :style="{
                      'border-bottom':
                        $route.name == 'home' ? '4px solid #b5fbe3' : '',
                    }"
                  >
                    Home
                  </div>
                </li></router-link
              >
              <router-link to="/publishers"
                ><li>
                  <div
                    :style="{
                      'border-bottom':
                        $route.name == 'publishers' ? '4px solid #b9f5e4' : '',
                    }"
                  >
                    Publishers
                  </div>
                </li></router-link
              >
              <router-link to="/buyers"
                ><li>
                  <div
                    :style="{
                      'border-bottom':
                        $route.name == 'buyers' ? '4px solid #01a3dc' : '',
                    }"
                  >
                    Buyers
                  </div>
                </li></router-link
              >
              <router-link to="/advertisers"
                ><li>
                  <div
                    :style="{
                      'border-bottom':
                        $route.name == 'advertisers' ? '4px solid #4bc6ac' : '',
                    }"
                  >
                    Advertisers
                  </div>
                </li></router-link
              >
              <li class="elv-a">
                <el-dropdown>
                  <span
                    class="el-dropdown-link elv"
                    :style="{
                      'border-bottom':
                        $route.name == 'contract' ? '4px solid #4bc6ac' : '',
                    }"
                  >
                    Bussiness
                    <!-- <el-icon class="el-icon--right">
                      <arrow-down />
                    </el-icon> -->
                  </span>
                  <template #dropdown>
                    <el-dropdown-menu>
                      <el-dropdown-item>
                        <router-link to="/contract/insertion-order"
                          >legal</router-link
                        ></el-dropdown-item
                      >
                    </el-dropdown-menu>
                  </template>
                </el-dropdown>
              </li>
              <router-link to="/aboutus"
                ><li>
                  <div
                    :style="{
                      'border-bottom':
                        $route.name == 'aboutus' ? '4px solid #fb9b48' : '',
                    }"
                  >
                    About Us
                  </div>
                </li></router-link
              >
              <li @click="toSSP"><div>Login</div></li>
            </ul>
          </div>
          <div class="dropdownList hidden-lg-and-up">
            <el-dropdown>
              <span class="el-dropdown-link">
                <el-icon class="el-icon">
                  <Menu />
                </el-icon>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    :style="{
                      background: $route.name == 'home' ? '#ecf5ff' : '',
                    }"
                    ><router-link to="/">Home</router-link></el-dropdown-item
                  >
                  <el-dropdown-item
                    :style="{
                      background: $route.name == 'publishers' ? '#ecf5ff' : '',
                    }"
                    ><router-link to="/publishers"
                      >Publishers</router-link
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    :style="{
                      background: $route.name == 'buyers' ? '#ecf5ff' : '',
                    }"
                    ><router-link to="/buyers"
                      >Buyers</router-link
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    :style="{
                      background: $route.name == 'contract' ? '#ecf5ff' : '',
                    }"
                  >
                    <el-dropdown>
                      <span class="el-dropdown-link elv">
                        Bussiness
                        <!-- <el-icon class="el-icon--right">
                      <arrow-down />
                    </el-icon> -->
                      </span>
                      <template #dropdown>
                        <el-dropdown-menu>
                          <el-dropdown-item>
                            <router-link to="/contract/insertion-order"
                              >legal</router-link
                            ></el-dropdown-item
                          >
                        </el-dropdown-menu>
                      </template>
                    </el-dropdown>
                  </el-dropdown-item>
                  <el-dropdown-item
                    :style="{
                      background: $route.name == 'advertisers' ? '#ecf5ff' : '',
                    }"
                    ><router-link to="/advertisers"
                      >Advertisers</router-link
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    :style="{
                      background: $route.name == 'aboutus' ? '#ecf5ff' : '',
                    }"
                    ><router-link to="/aboutus"
                      >About Us</router-link
                    ></el-dropdown-item
                  >
                  <el-dropdown-item
                    ><li @click="toSSP"><div>Login</div></li></el-dropdown-item
                  >
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-col>
      </el-row>
    </div>
    <div style="margin-top: 100px"></div>
  </div>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      model: "",
    };
  },
  methods: {
    toSSP() {
      window.open("http://pub-global.billowlink.com");
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  height: 70px;
  padding-top: 30px;
  background: #fff;
  box-shadow: 0px 5px 21px 8px rgba(238, 235, 235, 0.796);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 888;
  .logo {
    width: 100%;
    img {
      width: 200px;
    }
  }
  .navigation {
    ul li {
      list-style-type: none;
      float: left;
      margin-left: 30px;
      font-family: "Montserrat Light", Sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: #000;
      cursor: pointer;
    }
    div {
      line-height: 48px;
      height: 48px;
    }
  }
  .dropdownList {
    position: absolute;
    right: 60px;
    top: 5px;
    .el-dropdown-link {
      font-size: 40px;
      color: #4cbde4;
    }
  }
}
.elv {
  font-size: 18px;
  font-weight: 500;
  color: #000;
}

::v-deep .el-dropdown-menu__item {
  font-size: 16px;
  width: 100px;
  a {
    display: block;
    width: 100px;
  }
}
</style>
