<template>
<div>
    <Header></Header>
    <router-view></router-view>
    <Footer v-if="$route.meta.isHideFooter"></Footer>
</div>
</template>
<script>
import Header from '@/components/Header';
import Footer from '@/components/Footer.vue';
export default {
  name: "App",
  components:{Header,Footer},
};
</script>

<style lang="scss" scoped>
@import "@/assets/font/font.css";
</style>
