import { createRouter, createWebHistory } from "vue-router";
const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: {
      isHideFooter: true,
    },
  },
  {
    path: "/publishers",
    name: "publishers",
    component: () => import("../views/Publishers.vue"),
    meta: {
      isHideFooter: true,
    },
  },
  {
    path: "/buyers",
    name: "buyers",
    component: () => import("../views/Buyers.vue"),
    meta: {
      isHideFooter: true,
    },
  },
  {
    path: "/advertisers",
    name: "advertisers",
    component: () => import("../views/Advertisers.vue"),
    meta: {
      isHideFooter: true,
    },
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () => import("../views/AboutUs.vue"),
  },
  {
    path: "/privacy-policy",
    name: "privacy",
    component: () => import("../views/Privacy-policy"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/Contactus"),
  },
  {
    path: "/leader",
    name: "leader",
    component: () => import("../views/Leadership"),
  },
  {
    path: "/contract/insertion-order",
    name: "contract",
    component: () => import("../views/InsertionOrder.vue"),
  },
];
const router = createRouter({
  history: createWebHistory(),
  routes,
  //滚动行为:当路由进行切换跳转的时候，可以设置滚动条的位置
  scrollBehavior() {
    //设置top  left
    return { top: 0 };
  },
});

export default router;
