<template>
  <div>
    <div
      class="peopleImg wow animate__pulse"
      data-wow-iteration="1"
      data-wow-offset="200"
    ></div>
    <div class="info">
      <div class="talk">
        <div>Talk to us and we'll work with you to boost your business.</div>
        <button>Contact Us</button>
      </div>
      <div class="icon">
        <img
          src="https://static-global.billowlink.com/840/image/20220725/55c0da870d99499e878fcc280c1a20b9/529eddccf5514517a6671fcda6344352.png"
          alt=""
        />
        <div class="iconInfo">Company and Industry Updates</div>
        <button>Subscribe</button>
      </div>
      <div class="icon">
        <img
          src="https://static-global.billowlink.com/840/image/20220725/a8d06e7316fd450485be682f38cbe402/e6644532eadb496782513b37f8bb4270.png"
          alt=""
        />
        <div class="iconInfo">News and Events</div>
        <button>See Updates</button>
      </div>
      <div class="icon">
        <img
          src="https://static-global.billowlink.com/840/image/20220725/5a4674ea78d944059521c645069e67ed/ff36e11d9c4c4ffaab12f85032a34140.png"
          alt=""
        />
        <div class="iconInfo">Blog and Resources</div>
        <button>Read More</button>
      </div>
      <div class="icon">
        <img
          src="https://static-global.billowlink.com/840/image/20220725/84b4b7c3f2bd45bb9c4d48cf1f6cd3f7/ed71e2f063764c74b576a3a88e6fc6c6.png"
          alt=""
        />
        <div class="iconInfo">Join Our Algorian Family</div>
        <button>Careers</button>
      </div>
    </div>
    <div class="footer">
      <img
        class="billow"
        src="https://static-global.billowlink.com/840/image/20220725/c10fba9617e744f18673a608fa214f86/2c14fc898632450ab91089b412fe7015.png"
        alt=""
      />
      <div class="route">
        <router-link to="/publishers"
          ><el-icon style="vertical-align: -10%; font-weight: 900"
            ><DArrowRight
          /></el-icon>
          For Publishers &nbsp;&nbsp;</router-link
        >
        <router-link to="/buyers"
          ><el-icon style="vertical-align: -10%; font-weight: 900"
            ><DArrowRight
          /></el-icon>
          For Buyers &nbsp;&nbsp;</router-link
        >
        <router-link to="/advertisers"
          ><el-icon style="vertical-align: -10%; font-weight: 900"
            ><DArrowRight
          /></el-icon>
          For Advertisers &nbsp;&nbsp;</router-link
        >
      </div>
      <div style="display: flex; justify-content: center">
        <div class="logo">
          <span>Our Partners</span>
          <img
            src="https://static-global.billowlink.com/840/image/20220725/231f2835639e4e7aad8823e2e3e436d0/1e1a14cb06a24108b9fb8ebc268dfc18.png"
            alt=""
          />
        </div>
        <div class="box">
          <div class="footerInfo">
            <h3>Our Solutions</h3>
            <span>Billowlink Exchange</span>
            <span>Billowlink Creatives</span>
          </div>
          <div class="footerInfo">
            <h3>Resources</h3>
            <span>News and Articles</span>
            <span>Events</span>
            <span>Documentation</span>
          </div>
          <div class="footerInfo">
            <h3>Company</h3>
            <span>About Us</span>
            <span>Careers</span>
          </div>
          <div class="footerInfo">
            <h3>Legal</h3>
            <span @click="toPrivacy('privacy')" style="cursor: pointer"
              >Privacy Policy</span
            >
            <span @click="toPrivacy('ots')" style="cursor: pointer"
              >Website TOS</span
            >
            <span @click="toPrivacy('opt')" style="cursor: pointer"
              >Opt Out</span
            >
          </div>
        </div>
      </div>
      <div class="copy">
        Copyright 2020-2025 Billowlink All Rights Reserved.
        <a href="http://pub.billowlink.com/#/PrivacyPolicy" target="_bank"
          >&nbsp; &nbsp; | &nbsp; &nbsp;隐私政策</a
        ><a
          target="_blank"
          href="https://beian.miit.gov.cn/#/Integrated/index"
          rel="noreferrer"
          >&nbsp; &nbsp; | &nbsp; &nbsp;京ICP备2021035524号-8</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
  mounted() {
    this.$nextTick(() => {
      this.$wow.init();
    });
  },
  methods: {
    toPrivacy(item) {
      this.$router.push({ name: "privacy", params: { item } });
    },
  },
};
</script>

<style lang="scss" scoped>
.peopleImg {
  margin-top: 60px;
  height: 400px;
  background: url("https://static-global.billowlink.com/840/image/20220722/39350902d02c46d59e4e6af6bfaf8e26/8bfc5b0b5c9a4e7a87c93c2099ad7e78.png")
    no-repeat center/cover;
}
.info {
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  .talk {
    font-weight: 900;
    font-size: 26px;
    width: 20%;
    margin-right: 50px;
    line-height: 1.4em;
    height: 150px;
    button {
      border: none;
      width: 140px;
      height: 40px;
      margin-top: 20px;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
      cursor: pointer;
      background: url("https://static-global.billowlink.com/840/image/20220725/8fef91de3fb149cfa423dda8e41f4aba/94cae6b5755648ccad8e0a2626bc44a5.png");
      background-size: 100% 100%;
    }
  }
  .icon {
    width: 10%;
    margin: 0 20px;
    text-align: center;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    img {
      width: 50px;
      margin-top: 70px;
    }
    .iconInfo {
      color: #3a3a3a;
      font-size: 14px;
      font-weight: 400;
      line-height: 1.5em;
      height: 44px;
    }
    button {
      border: none;
      width: 120px;
      height: 30px;
      color: #fff;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      background: url("https://static-global.billowlink.com/840/image/20220725/8fef91de3fb149cfa423dda8e41f4aba/94cae6b5755648ccad8e0a2626bc44a5.png");
      background-size: 100% 100%;
      margin-bottom: 70px;
    }
  }
}
.footer {
  height: 450px;
  background: url("https://static-global.billowlink.com/840/image/20220725/82948392819448e2843ae531dda749d1/20d2ea1fea594518beb325ec02ea6543.png")
    no-repeat;
  background-size: 100% 100%;
  position: relative;
  .billow {
    margin: 50px 0 70px 150px;
    width: 200px;
  }
  .route {
    position: absolute;
    right: 300px;
    top: 60px;
    font-size: 20px;
    a {
      color: #fff;
    }
  }
  .logo {
    span {
      font-weight: bold;
      font-size: 20px;
      color: #fff;
    }
    img {
      vertical-align: top;
      width: 250px;
      margin-left: 40px;
    }
  }
  .box {
    display: flex;
    margin-left: 50px;
    .footerInfo {
      display: flex;
      flex-direction: column;
      margin: 0 20px;

      h3 {
        color: #fff;
        font-size: 18px;
        font-weight: bolder;
        margin-bottom: 10px;
        padding: 0;
      }
      span {
        color: #ccc;
        line-height: 2em;
        font-weight: bold;
        padding-left: 4px;
        font-family: "Montserrat Hairline";
      }
    }
  }
  .copy {
    position: absolute;
    left: 140px;
    bottom: 16px;
    color: #ccc;
    font-size: 14px;
    a {
      color: #ccc;
    }
  }
}
</style>
